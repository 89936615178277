import { useState, useEffect, useContext } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import AppContext from '../context/AppContext'
import Layout from '../components/Layout'
import UserWarning from '../components/Layout/UserWarning'
import Connect from '../components/Connect'
import Mint from '../components/Mint'
import Ticket from '../components/Ticket'

export default function Event() {
    const { didAppInitialized, doesUserHasNft, getEventData, err, taskIdChecker, isGoogleResponse } = useContext(AppContext)
    const { eventId } = useParams()
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const [isReqSent, setIsReqSent] = useState(false)
    const type = searchParams.get('type')
    const taskId = searchParams.get('task_id')

    useEffect(() => {
        if (isReqSent || didAppInitialized !== true) return
        if (taskId !== null) taskIdChecker(taskId)
        getEventData(eventId)
        setIsReqSent(true)
    }, [getEventData, eventId, isReqSent, taskId, didAppInitialized, taskIdChecker])

    return (
        <Layout>
            <div className="eventPageContainer">
                {err === '404' ? (
                    <UserWarning type="notFound" />
                ) : err === 'networkErr' ? (
                    <>
                        <h1 className="blueGradient">Error</h1>
                        <h2>
                            The FirstBatch APIs <br /> could not be accessed
                        </h2>
                        <h3>Please wait for a while or try again</h3>
                        <button className="retryBtn" onClick={() => getEventData(eventId)}>
                            Retry
                        </button>
                    </>
                ) : err === 'taskId' ? (
                    <>
                        <h1 className="blueGradient">Error</h1>
                        <h2>Your authorization expired</h2>
                        <h3>Please re-authorize from the link that provided to you.</h3>
                    </>
                ) : doesUserHasNft ? (
                    <Ticket />
                ) : type !== null && type && taskId === null && !isGoogleResponse ? (
                    <Connect eventId={eventId} type={type} />
                ) : (type === null && taskId !== null) || isGoogleResponse ? (
                    <Mint />
                ) : (
                    <>
                        <h1 className="greenGradient">Error</h1>
                        <h2>Parameters not present.</h2>
                        <h3>Check the link provided to you.</h3>
                    </>
                )}
            </div>
        </Layout>
    )
}
