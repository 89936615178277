import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Event from './pages/Event'
import Verification from './pages/Verification'
import NotFound from './pages/NotFound'
import DappUI from './pages/DappUI'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/event/:eventId" element={<Event />} />
                <Route path="/access" element={<Verification />} />
                <Route path="/management" element={<DappUI />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
