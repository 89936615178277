import { useState, useContext } from 'react'
import LoadingIcons from 'react-loading-icons'
import { useSwitchNetwork, useNetwork } from 'wagmi'
import AppContext from '../../context/AppContext'
import WalletConnectionModal from '../Modals/WalletConnectionModal.js'
import ProcessSteps from '../ProcessSteps.js'
import CustomRKButton from '../CustomRKButton'

export default function UserWarning({ type }) {
    const { initApp } = useContext(AppContext)
    const { switchNetwork, isLoading } = useSwitchNetwork()
    const { chain } = useNetwork()
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="userWarningContainer">
            {type !== 'notFound' && (
                <>
                    <div className="bgGradient">
                        <img src="/assets/bgGradient.png" alt="bg gradient" />
                    </div>
                    <div className="brandingContainer">
                        <img src="/assets/logo.png" alt="FirstBatch logo" />
                        <span>FirstBatch</span>
                    </div>
                </>
            )}
            <h1>
                <span className="greenGradient">Ticket</span>Mint
            </h1>
            <h4 className={`${type === 'notFound' && 'mw-300'}`}>
                {type === 'unauthorized'
                    ? 'Welcome back, connect wallet to continue'
                    : type === 'incorrectChain'
                    ? `Connected to ${chain.name || 'wrong chain'}, please switch to Polygon Mumbai.`
                    : type === 'notFound'
                    ? "We're sorry, but this page does not exist. We're at the link you got."
                    : type === 'retryInit' && 'The initialization of the app was unsuccessful. Please try it again.'}
            </h4>
            {type === 'unauthorized' && <CustomRKButton />}
            {type === 'incorrectChain' || type === 'retryInit' ? (
                <button
                    onClick={() => {
                        !isLoading &&
                            (type === 'unauthorized'
                                ? setShowModal(true)
                                : type === 'incorrectChain'
                                ? switchNetwork(80001)
                                : type === 'retryInit' && initApp())
                    }}
                >
                    {type === 'unauthorized' ? (
                        'Connect'
                    ) : type === 'incorrectChain' ? (
                        isLoading ? (
                            <LoadingIcons.Puff />
                        ) : (
                            'Switch Chain'
                        )
                    ) : (
                        type === 'retryInit' && 'Retry'
                    )}
                </button>
            ) : type === 'initializing' ? (
                <ProcessSteps type="init" />
            ) : (
                type === 'loadingEvent' && <ProcessSteps type="loadingEvent" />
            )}
            {showModal && <WalletConnectionModal onClose={() => setShowModal(false)} />}
        </div>
    )
}
