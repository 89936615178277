import { useContext } from 'react'
import { useGoogleLogout } from 'react-google-login'
import LoadingIcons from 'react-loading-icons'
import AppContext from '../context/AppContext'
import ProcessSteps from './ProcessSteps'

export default function Mint() {
    const {
        startMintProcess,
        mintingStep,
        mintingErr,
        eventData,
        userDeclined,
        connectedGoogleAccount,
        setIsGoogleResponse,
        setConnectedGoogleAccount,
    } = useContext(AppContext)

    const { signOut } = useGoogleLogout({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onLogoutSuccess: () => {
            setIsGoogleResponse(false)
            setConnectedGoogleAccount('')
        },
        onFailure: () => {
            console.log('logout fail')
        },
    })

    return (
        <div className="mintContainer">
            {userDeclined ? (
                <div className="declinedContainer">
                    <img src="/assets/declined.svg" alt="declined icon" />
                    <h1 className="blueGradient">Declined</h1>
                    <h2>Not Eligible</h2>
                    <h3>You are not eligible to participate in this event.</h3>
                </div>
            ) : (
                <>
                    <h1 className="greenGradient">Mint Your Event Ticket</h1>
                    <h2>
                        {/* This ticket is for <strong>{eventData && eventData.event_name}</strong>, hosted by{' '}
                        <strong>{eventData && eventData.dapp_name}</strong> */}
                        {eventData && eventData.title}
                    </h2>
                    <h3>
                        by <img src="/assets/foresightVentures.svg" alt="Host logo" />
                    </h3>
                    <button
                        onClick={() => {
                            mintingStep === null ? startMintProcess() : mintingErr && startMintProcess()
                        }}
                    >
                        {mintingStep === null ? 'Mint' : mintingErr ? 'Retry' : mintingStep >= 0 && <LoadingIcons.Puff />}
                    </button>
                    {connectedGoogleAccount !== '' && mintingStep === null && (
                        <div className="googleAccContainer">
                            <span>
                                Connected with <strong>{connectedGoogleAccount}</strong>
                            </span>
                            <button onClick={signOut}>Logout</button>
                        </div>
                    )}
                    {mintingStep !== null && <ProcessSteps type="mint" />}
                </>
            )}
        </div>
    )
}
