import { useState, useContext } from 'react'
import { BsArrowUpShort } from 'react-icons/bs'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { VscSave, VscSaveAll, VscCopy, VscEdit, VscDebugStepBack } from 'react-icons/vsc'
import { TiUserDelete } from 'react-icons/ti'
import { SiTwitter, SiDiscord, SiGoogle } from 'react-icons/si'
import AppContext from '../context/AppContext'
import Puff from 'react-loading-icons/dist/esm/components/puff'

export default function Event({
    event,
    index,
    focusedEventId,
    setFocusedEventId,
    focusedRow,
    setFocusedRow,
    userInput,
    setUserInput,
    stagedUsers,
    setStagedUsers,
}) {
    const { updateTicketRoots } = useContext(AppContext)
    const [isValidInput, setIsValidInput] = useState(null)
    const [errMsg, setErrMsg] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [copiedLink, setCopiedLink] = useState('')
    const [isRootUpdate, setIsRootUpdate] = useState(false)
    const [isReq, setIsReq] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [usersToRemove, setUsersToRemove] = useState([])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    return (
        <div className={`event ${focusedEventId === index ? 'activeEvent' : ''}`}>
            <div
                className="accordionTop"
                onClick={() => {
                    focusedEventId === index ? setFocusedEventId('') : setFocusedEventId(index)
                }}
            >
                <div className="left">
                    <div className="infoRow infoRow1">
                        <div className="label">Event name:</div>
                        <div className="info">{event.event_name}</div>
                    </div>
                    <div className="infoRow infoRow2">
                        <div className="label">Dapp name:</div>
                        <div className="info">{event.dapp_name}</div>
                    </div>
                    <div className="infoRow infoRow3">
                        <div className="label">Contract address:</div>
                        <div className="info">{event.contract_address}</div>
                    </div>
                </div>
                <div className="right">
                    <div className="line"></div>
                    {event.socials.includes('google') && <SiGoogle />}
                    {event.socials.includes('twitter') && <SiTwitter />}
                    {event.socials.includes('discord') && <SiDiscord />}
                </div>
            </div>
            <div className="accordionBottom">
                <div
                    className={`bottomRow eligibleUsers ${focusedRow === 'eligibleUsers' ? 'activeRow' : ''}`}
                    onClick={() => setFocusedRow('eligibleUsers')}
                >
                    <div className="top">
                        <div className="left">
                            <span>Eligible Users ({event.commitments.length})</span>
                        </div>
                        <div className="right">
                            <BsArrowUpShort />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="userListContainer">
                            {event.user_list ? (
                                event.user_list.length === 0 ? (
                                    <p>Currently There is no User.</p>
                                ) : (
                                    event.user_list.map((user, userIndex) => {
                                        return (
                                            <span
                                                key={`user${userIndex}`}
                                                className={usersToRemove.includes(user) ? 'toDelete' : ''}
                                            >
                                                {user}
                                                {isEditing && (
                                                    <div
                                                        className="deleteBtn"
                                                        onClick={() => {
                                                            !isReq &&
                                                                (usersToRemove.includes(user)
                                                                    ? setUsersToRemove(
                                                                          usersToRemove.filter((u) => u !== user)
                                                                      )
                                                                    : setUsersToRemove([...usersToRemove, user]))
                                                        }}
                                                    >
                                                        <TiUserDelete />
                                                    </div>
                                                )}
                                            </span>
                                        )
                                    })
                                )
                            ) : event.commitments.length === 0 ? (
                                <p>Currently There is no User.</p>
                            ) : (
                                event.commitments.map((commitment, commitmentIndex) => {
                                    return (
                                        <span
                                            key={`commitment${commitmentIndex}`}
                                            className={usersToRemove.includes(commitment) ? 'toDelete' : ''}
                                        >
                                            {commitment}
                                            {isEditing && (
                                                <div
                                                    className="deleteBtn"
                                                    onClick={() => {
                                                        !isReq &&
                                                            (usersToRemove.includes(commitment)
                                                                ? setUsersToRemove(
                                                                      usersToRemove.filter((c) => c === commitment)
                                                                  )
                                                                : setUsersToRemove([...usersToRemove, commitment]))
                                                    }}
                                                >
                                                    <TiUserDelete />
                                                </div>
                                            )}
                                        </span>
                                    )
                                })
                            )}
                        </div>
                        <div className="btnContainer">
                            {event.commitments.length !== 0 &&
                                (isEditing ? (
                                    usersToRemove.length === 0 ? (
                                        <VscDebugStepBack onClick={() => setIsEditing(false)} />
                                    ) : isReq ? (
                                        <Puff />
                                    ) : (
                                        <VscSave
                                            onClick={async () => {
                                                let bodyObj = {
                                                    eventID: event.event_id,
                                                    userList: event.user_list.filter(
                                                        (user) => !usersToRemove.includes(user)
                                                    ),
                                                }
                                                setIsReq(true)
                                                await updateTicketRoots(bodyObj, event.dapp_name)
                                                setIsReq(false)
                                                setIsEditing(false)
                                                setUsersToRemove([])
                                            }}
                                        />
                                    )
                                ) : (
                                    <VscEdit onClick={() => setIsEditing(true)} />
                                ))}
                        </div>
                    </div>
                </div>
                <div
                    className={`bottomRow newUserInput ${focusedRow === 'newUserInput' && 'activeRow'}`}
                    onClick={() => setFocusedRow('newUserInput')}
                >
                    <div className="top">
                        <div className="left">Add New User</div>
                        <div className="right">
                            <BsArrowUpShort />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="addNewUserContainer">
                            <div className="topRow">
                                <form
                                    className={`inputContainer ${isValidInput === false ? 'invalid' : ''}`}
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (userInput === '' || isRootUpdate) return
                                        if (event.socials.includes('twitter') || event.socials.includes('discord')) {
                                            setIsValidInput(false)
                                            setErrMsg('This event is not ready to update.')
                                            setTimeout(() => {
                                                setErrMsg('')
                                                setIsValidInput(null)
                                            }, 3000)
                                            return
                                        }
                                        if (isValidInput === null) {
                                            if (validateEmail(userInput)) {
                                                stagedUsers.push(userInput)
                                                setUserInput('')
                                            } else {
                                                setIsValidInput(false)
                                                setErrMsg('Please enter a valid gmail account.')
                                                setTimeout(() => {
                                                    setErrMsg('')
                                                    setIsValidInput(null)
                                                }, 3000)
                                            }
                                        }
                                    }}
                                >
                                    <input
                                        onChange={(e) => setUserInput(e.target.value)}
                                        value={userInput}
                                        placeholder="Enter User Identifier"
                                        type="text"
                                    />
                                    <button className="addBtn">
                                        <div className="line"></div>
                                        <AiOutlineUserAdd />
                                    </button>
                                </form>
                                {errMsg !== '' && <span className="errMsg">{errMsg}</span>}
                                {stagedUsers.length !== 0 && (
                                    <div
                                        className="approveBtn"
                                        onClick={async () => {
                                            let newUserList = [...event.user_list, ...stagedUsers]
                                            let bodyObj = {
                                                eventID: event.event_id,
                                                userList: newUserList,
                                            }
                                            setIsReq(true)
                                            const res = await updateTicketRoots(bodyObj, event.dapp_name)
                                            setIsReq(false)
                                            if (res === 'Success') {
                                                setStagedUsers([])
                                                setIsRootUpdate(true)
                                                setFocusedRow('eligibleUsers')
                                                setTimeout(() => {
                                                    setIsRootUpdate(false)
                                                }, 5000)
                                            }
                                        }}
                                    >
                                        {isReq ? (
                                            <Puff />
                                        ) : stagedUsers.length === 1 ? (
                                            <VscSave />
                                        ) : (
                                            stagedUsers.length > 1 && <VscSaveAll />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="stagedUsers">
                                {stagedUsers.length !== 0 &&
                                    stagedUsers.map((user, index) => (
                                        <span key={`stagedUser${index}`}>
                                            <div
                                                className="deleteBtn"
                                                onClick={() => {
                                                    let copy = [...stagedUsers]
                                                    copy.splice(index, 1)
                                                    copy.length === 0 && copy.splice(index, 1)
                                                    setStagedUsers(copy)
                                                }}
                                            >
                                                <TiUserDelete />
                                            </div>
                                            {user}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`bottomRow eventLinks ${focusedRow === 'eventLinks' ? 'activeRow' : ''}`}
                    onClick={() => setFocusedRow('eventLinks')}
                >
                    <div className="top">
                        <div className="left">
                            <span>Links ({Object.keys(event.link).length})</span>
                        </div>
                        <div className="right">
                            <BsArrowUpShort />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="linksContainer">
                            {Object.entries(event.link).map((link, index) => {
                                return (
                                    <div key={`link${index}`} className="row">
                                        {link[0] === 'google' && <SiGoogle />}
                                        {link[0] === 'twitter' && <SiTwitter />}
                                        {link[0] === 'discord' && <SiDiscord />}
                                        <a href={link[1]} rel="noreferrer" target="_blank">
                                            {link[1]}
                                        </a>
                                        <div
                                            className="copyBtn"
                                            onClick={() => {
                                                if (isCopied) return
                                                navigator.clipboard.writeText(link[1])
                                                setIsCopied(true)
                                                setCopiedLink(link[1])
                                                setTimeout(() => {
                                                    setIsCopied(false)
                                                    setCopiedLink('')
                                                }, 5000)
                                            }}
                                        >
                                            {copiedLink === link[1] ? (
                                                'Link Copied'
                                            ) : (
                                                <>
                                                    Copy <VscCopy />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
