/* global BigInt */
import { useState, createContext } from 'react'
import { useAccount } from 'wagmi'
import Web3 from 'web3'
import { Biconomy } from '@biconomy/mexa'
import { utils } from 'ethers'

import {
    imageUploadURL,
    jsonUploadURL,
    polygonScanURL,
    getTicketingEventURL,
    taskIdCheckerURL,
    googleURL,
    getTicketingEventsURL,
    ticketingLoginURL,
    updateTicketRootsURL,
} from '../helpers/endpoints'
import ContractABI from '../helpers/eventTicketABI.json'

const { Identity } = require('@semaphore-protocol/identity')
const { Group } = require('@semaphore-protocol/group')
const { generateProof } = require('@semaphore-protocol/proof')
const { packToSolidityProof } = require('@semaphore-protocol/proof')

const wasmFilePath = '/assets/semaphore.wasm'
const zkeyFilePath = '/assets/semaphore.zkey'

let web3, web3Window

let domainData = {
    name: 'EventTicket',
    version: '1',
    verifyingContract: '',
    salt: '0x' + (80001).toString(16).padStart(64, '0'),
}

const domainType = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'verifyingContract', type: 'address' },
    { name: 'salt', type: 'bytes32' },
]

const metaTransactionType = [
    { name: 'nonce', type: 'uint256' },
    { name: 'from', type: 'address' },
    { name: 'functionSignature', type: 'bytes' },
]

const AppContext = createContext()

export const AppProvider = ({ children }) => {
    const [didAppInitialized, setDidAppInitialized] = useState(null)
    const [isInitializing, setIsInitializing] = useState(false)
    const [eventData, setEventData] = useState(null)
    const [err, setErr] = useState(null)
    const [mintingStep, setMintingStep] = useState(null)
    const [mintingErr, setMintingErr] = useState(false)
    const [doesUserHasNft, setDoesUserHasNft] = useState(false)
    const [ticketBase64, setTicketBase64] = useState(null)
    const [isEventPending, setIsEventPending] = useState(null)
    const [isTaskIdPending, setIsTaskIdPending] = useState(null)
    const [taskIdResponse, setTaskIdResponse] = useState(null)
    const [userDeclined, setUserDeclined] = useState(false)
    const [isGoogleResponse, setIsGoogleResponse] = useState(false)
    const [connectedGoogleAccount, setConnectedGoogleAccount] = useState('')
    const [dappAuth, setDappAuth] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [dappEvents, setDappEvents] = useState([])

    const { address: wid } = useAccount({
        onConnect() {
            initApp()
        },
    })

    const isLogsActive = process.env.NODE_ENV === 'development' ? true : false

    const initApp = () => {
        if (didAppInitialized || isInitializing) return
        setIsInitializing(true)

        const maticProvider = new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL)
        const biconomy = new Biconomy(maticProvider, { apiKey: process.env.REACT_APP_BICONOMY_API_KEY })

        web3 = new Web3(biconomy)
        web3Window = new Web3(window.ethereum)

        biconomy
            .onEvent(biconomy.READY, async () => {
                setIsInitializing(false)
                setDidAppInitialized(true)
            })
            .onEvent(biconomy.ERROR, (error, message) => {
                setIsInitializing(false)
                setDidAppInitialized(false)
                console.log(error, message)
            })
    }

    const dappLogin = async (dappName, password) => {
        fetch(ticketingLoginURL(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ dapp_name: dappName, password }),
        })
            .then((response) => response.json())
            .then((data) => {
                isLogsActive && console.log('dappLogin res =>', data)
                if (data.code === 401) {
                    setIsPending(false)
                    setErrMsg('No Permission')
                    setTimeout(() => {
                        setErrMsg('')
                    }, 3000)
                } else if (data.code === 200) {
                    if (data.data.code === 403) {
                        setIsPending(false)
                        setErrMsg('Invalid Credentials!')
                        setTimeout(() => {
                            setErrMsg('')
                        }, 3000)
                    } else if (data.data.status === 'There is no dApp for ticketing!') {
                        setIsPending(false)
                        setErrMsg(data.data.status)
                        setTimeout(() => {
                            setErrMsg('')
                        }, 3000)
                    } else {
                        getDappEvents(dappName)
                    }
                } else {
                    setIsPending(false)
                    setErrMsg('Please try again later.')
                    setTimeout(() => {
                        setErrMsg('')
                    }, 3000)
                }
            })
            .catch((err) => {
                console.log('dappLogin err =>', err)
                setIsPending(false)
                setErrMsg('Please try again later.')
                setTimeout(() => {
                    setErrMsg('')
                }, 3000)
            })
    }

    const getDappEvents = (dappName) => {
        fetch(getTicketingEventsURL(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ dapp_name: dappName }),
        })
            .then((response) => response.json())
            .then((data) => {
                isLogsActive && console.log('getDappEvents res =>', data)
                if (data.code === 200) {
                    setIsPending(false)
                    setDappAuth(true)
                    setDappEvents(data.data)
                } else {
                    setIsPending(false)
                    setErrMsg('Please try again later.')
                    setTimeout(() => {
                        setErrMsg('')
                    }, 3000)
                }
            })
            .catch((err) => {
                console.log('getDappEvents err =>', err)
                setIsPending(false)
                setErrMsg('Please try again later.')
                setTimeout(() => {
                    setErrMsg('')
                }, 3000)
            })
    }

    const updateTicketRoots = async (body, dappName) => {
        return fetch(updateTicketRootsURL(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        })
            .then((response) => response.text())
            .then((data) => {
                isLogsActive && console.log('updateTicketRoots res =>', data)
                if (data === 'Success') {
                    getDappEvents(dappName)
                    return data
                }
            })
            .catch((err) => {
                console.log('updateTicketRoots err =>', err)
                return err
            })
    }

    /////////////////////////////
    // Init Event Page - Start //
    /////////////////////////////
    const getEventData = (event_id) => {
        if (event_id === undefined) return
        setIsEventPending(true)

        fetch(getTicketingEventURL(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ event_id }),
        })
            .then((response) => response.json())
            .then((data) => {
                isLogsActive && console.log('getEventData succ =>', data)
                data.data === null ? setErr('404') : nftCheck(data.data)
            })
            .catch((err) => {
                setErr('networkErr')
                setIsEventPending(false)
                console.log('getEventData err =>', err)
            })
    }

    const nftCheck = async (evtData) => {
        isLogsActive && console.log('nftCheck invoked with =>', evtData)
        setEventData(evtData)
        domainData.verifyingContract = evtData.contract_address

        const ticketContract = new web3.eth.Contract(ContractABI, evtData.contract_address)
        const balanceOfUser = await ticketContract.methods.balanceOf(wid).call()

        isLogsActive && console.log('balanceOfUser =>', balanceOfUser)

        if (balanceOfUser === '1') {
            const token = await ticketContract.methods.tokenOfOwnerByIndex(wid, 0).call()
            const tokenMetadata = await ticketContract.methods.tokenURI(token).call()

            fetch(tokenMetadata)
                .then((response) => response.json())
                .then((data) => {
                    fetch(data.imgUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                            var reader = new FileReader()
                            reader.onload = function () {
                                setTicketBase64(this.result)
                                setDoesUserHasNft(true)
                                setIsEventPending(false)
                            }
                            reader.readAsDataURL(blob)
                        })
                })
                .catch((err) => {
                    console.log('fetchNftMetadata err =>', err)
                })
        } else if (balanceOfUser === '0') {
            setDoesUserHasNft(false)
            setIsEventPending(false)
        }
    }

    const taskIdChecker = (taskId) => {
        setIsTaskIdPending(true)

        const interval = setInterval(() => {
            intervalRequest(taskId)
        }, 1000 * 5)

        const intervalRequest = async (taskId) => {
            isLogsActive && console.log('intervalRequest invoked')

            fetch(taskIdCheckerURL(taskId))
                .then((response) => response.json())
                .then((data) => {
                    isLogsActive && console.log('taskId res =>', data)

                    if (data.task_status === 'complete' && data.task_result.code === 200) {
                        clearInterval(interval)
                        setIsTaskIdPending(false)
                        setTaskIdResponse(data)
                    } else if (data.task_result.message === 'This account is already used!') {
                        clearInterval(interval)
                        setIsTaskIdPending(false)
                    }
                })
                .catch((err) => {
                    clearInterval(interval)
                    setIsTaskIdPending(false)
                    setErr('taskId')
                    console.log('taskId interval err =>', err)
                })
        }
    }

    const googleTaskIdChecker = (args) => {
        isLogsActive && console.log('google taskIdChecker invoked with =>', args)
        const { access_token, token, setClickedBtn } = args

        fetch(googleURL(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ access_token, token }),
        })
            .then((response) => response.json())
            .then((data) => {
                isLogsActive && console.log('signedMsg res =>', data)
                if (data.message.signed_message !== null) {
                    setTaskIdResponse({ task_result: { message: { signed_message: data.message.signed_message } } })
                    setIsGoogleResponse(true)
                }
                setClickedBtn(null)
            })
            .catch((err) => {
                console.log('googleURL err =>', err)
                setClickedBtn(null)
            })
    }
    ///////////////////////////
    // Init Event Page - End //
    ///////////////////////////

    /////////////////////
    // Minting - START //
    /////////////////////
    const startMintProcess = () => {
        isLogsActive && console.log('startMintProcess invoked')

        setMintingStep(0)
        setMintingErr(false)

        genProof()
    }

    const genProof = async () => {
        isLogsActive && console.log('genProof invoked')

        setMintingStep(1)

        const identity = new Identity(taskIdResponse.task_result.message.signed_message)
        const identityCommitment = identity.generateCommitment()
        const idCommitment = identityCommitment.toString()
        const signal = '<RANDOM GENERATED STRING>'
        const bytes32signal = utils.formatBytes32String(signal)
        const group = new Group(20, BigInt(0))

        let id_commitments, fullProof

        id_commitments = eventData.commitments
        id_commitments.forEach((id_commitment) => group.addMember(BigInt(id_commitment)))

        isLogsActive && console.log('id_commitments =>', id_commitments)
        isLogsActive && console.log('identity =>', identity)
        isLogsActive && console.log('identityCommitment =>', identityCommitment)
        isLogsActive && console.log('idCommitment =>', idCommitment)
        isLogsActive && console.log('bytes32signal =>', bytes32signal)
        isLogsActive && console.log('group =>', group)

        let randomInt = Math.floor(Math.random() * 1000000)

        // let externalNullifier = '666' || group.root

        let externalNullifier = randomInt

        isLogsActive && console.log('externalNullifier =>', externalNullifier)

        try {
            fullProof = await generateProof(identity, group, externalNullifier, bytes32signal, {
                zkeyFilePath,
                wasmFilePath,
            })
            fullProof.proof = packToSolidityProof(fullProof.proof)
        } catch (err) {
            setUserDeclined(true)
            console.log('fullProof err =>', err)
            return
        }

        isLogsActive && console.log('fullProof =>', fullProof)

        const txObj = {
            groupId: '2',
            bytes32signal,
            nullifierHash: fullProof.publicSignals.nullifierHash,
            externalNullifier: fullProof.publicSignals.externalNullifier,
            proof: fullProof.proof,
        }

        isLogsActive && console.log('txObj =>', txObj)

        generateMetadata({
            type: 'base64',
            url: 'https://ticketing-app-git-prod-firstbatch.vercel.app/assets/foresightTicket.png',
            txObj: txObj,
        })
    }

    const generateMetadata = (args) => {
        isLogsActive && console.log('generateMetadata invoked with =>', args)

        args.type === 'base64' && setMintingStep(2)

        args.type === 'base64'
            ? fetch(args.url)
                  .then((response) => response.blob())
                  .then((blob) => {
                      const reader = new FileReader()
                      reader.readAsDataURL(blob)
                      reader.onload = function () {
                          uploadToIpfs({ type: 'base64', base64: this.result, txObj: args.txObj })
                      }
                  })
                  .catch((err) => {
                      setMintingErr(true)
                      console.log('generateNftMetadata err =>', err)
                  })
            : args.type === 'json' &&
              uploadToIpfs({
                  type: 'json',
                  metadata: {
                      key1: 'val1',
                      key2: 'val2',
                      imgUrl: args.url,
                  },
                  txObj: args.txObj,
              })
    }

    const uploadToIpfs = (args) => {
        isLogsActive && console.log('uploadToIpfs invoked with =>', args)

        // eslint-disable-next-line no-unused-expressions
        args.type === 'base64'
            ? fetch(imageUploadURL(), {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ imageData: args.base64 }),
              })
                  .then((response) => response.json())
                  .then((data) => {
                      isLogsActive && console.log('uploadToIpfs base64 res =>', data)

                      data.success
                          ? generateMetadata({ type: 'json', url: data.pinataUrl, txObj: args.txObj })
                          : setMintingErr(true)
                  })
                  .catch((err) => {
                      setMintingErr(true)
                      console.log('upload base64 err =>', err)
                  })
            : args.type === 'json' &&
              fetch(jsonUploadURL(), {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(args.metadata),
              })
                  .then((response) => response.json())
                  .then((data) => {
                      isLogsActive && console.log('upload json succ =>', data)
                      data.success ? metaTx(data.pinataUrl, args.txObj) : setMintingErr(true)
                  })
                  .catch((err) => {
                      setMintingErr(true)
                      console.log('upload json err =>', err)
                  })
    }

    const metaTx = async (ipfsUrl, txObj) => {
        isLogsActive && console.log('metaTx invoked with =>', ipfsUrl)

        setMintingStep(3)

        const newContract = new web3.eth.Contract(ContractABI, eventData.contract_address)
        const nonce = await newContract.methods.getNonce(wid).call()
        const functionSignature = await newContract.methods
            .safeMint(ipfsUrl, txObj.groupId, txObj.bytes32signal, txObj.nullifierHash, txObj.externalNullifier, txObj.proof)
            .encodeABI()

        const dataToSign = JSON.stringify({
            types: { EIP712Domain: domainType, MetaTransaction: metaTransactionType },
            domain: domainData,
            primaryType: 'MetaTransaction',
            message: {
                nonce: parseInt(nonce),
                from: wid,
                functionSignature,
            },
        })

        web3Window.currentProvider.sendAsync(
            {
                jsonrpc: '2.0',
                id: 999999999999,
                method: 'eth_signTypedData_v4',
                params: [wid, dataToSign],
            },
            async function (err, result) {
                if (err) {
                    setMintingErr(true)
                    return console.error(err)
                } else if (result && result.result) {
                    setMintingStep(4)

                    const signature = result.result
                    const r = signature.substring(0, 66)
                    const s = '0x' + signature.substring(66, 130)
                    let v = '0x' + signature.substring(130, 132)
                    v = web3.utils.hexToNumber(v)
                    if (![27, 28].includes(v)) v += 27

                    const timeout = setTimeout(() => {
                        setMintingErr(true)
                    }, 1000 * 60 * 4)

                    let txEvent = false

                    try {
                        txEvent = await newContract.methods
                            .executeMetaTransaction(wid, functionSignature, r, s, v)
                            .send({ from: wid })
                    } catch (err) {
                        setMintingErr(true)
                        console.log('txEvent err =>', err)
                    }

                    if (!txEvent) {
                        setMintingErr(true)
                        return
                    }

                    isLogsActive && console.log('txEvent =>', txEvent)

                    clearTimeout(timeout)
                    txStatusChecker(txEvent.transactionHash)
                } else {
                    setMintingErr(true)
                    console.log(err)
                }
            }
        )
    }

    const txStatusChecker = async (txHash) => {
        isLogsActive && console.log('txStatusChecker invoked with =>', txHash)

        setMintingStep(5)

        const interval = setInterval(() => {
            intervalRequest(txHash)
        }, 5000)

        const intervalRequest = async (txHash) => {
            fetch(polygonScanURL(txHash))
                //
                .then((response) => response.json())
                .then((data) => {
                    isLogsActive && console.log('txStatusChecker res =>', data)

                    if (data.status === '1' || data.status === '0') {
                        if (data.status === '1') {
                            setMintingStep(6)
                            nftCheck(eventData)
                        } else if (data.status === 0) {
                            setMintingErr(true)
                        }
                        clearInterval(interval)
                    }
                })
                .catch((err) => {
                    console.log('rootUpdateChecker err =>', err)
                })
        }
    }
    ///////////////////
    // Minting - END //
    ///////////////////

    const metaTxBurn = async () => {
        isLogsActive && console.log('MetaTxBurn invoked')
        const ticketContract = new web3.eth.Contract(ContractABI, eventData.contract_address)
        const nonce = await ticketContract.methods.getNonce(wid).call()
        const token = await ticketContract.methods.tokenOfOwnerByIndex(wid, 0).call()
        const functionSignature = await ticketContract.methods.burnNFT(token).encodeABI()
        const dataToSign = JSON.stringify({
            types: { EIP712Domain: domainType, MetaTransaction: metaTransactionType },
            domain: domainData,
            primaryType: 'MetaTransaction',
            message: {
                nonce: parseInt(nonce),
                from: wid,
                functionSignature,
            },
        })

        web3Window.currentProvider.sendAsync(
            {
                jsonrpc: '2.0',
                id: 999999999999,
                method: 'eth_signTypedData_v4',
                params: [wid, dataToSign],
            },
            async function (err, result) {
                if (err) {
                    return console.error(err)
                } else if (result && result.result) {
                    const signature = result.result
                    const r = signature.substring(0, 66)
                    const s = '0x' + signature.substring(66, 130)
                    let v = '0x' + signature.substring(130, 132)
                    v = web3.utils.hexToNumber(v)
                    if (![27, 28].includes(v)) v += 27

                    let txEvent

                    try {
                        txEvent = await ticketContract.methods
                            .executeMetaTransaction(wid, functionSignature, r, s, v)
                            .send({ from: wid })
                    } catch (err) {
                        console.log('txEvent err =>', err)
                    }

                    isLogsActive && console.log('txEvent =>', txEvent)
                } else {
                    console.log(err)
                }
            }
        )
    }

    return (
        <AppContext.Provider
            value={{
                didAppInitialized,
                isInitializing,
                initApp,
                startMintProcess,
                mintingStep,
                mintingErr,
                eventData,
                getEventData,
                err,
                taskIdChecker,
                isTaskIdPending,
                metaTxBurn,
                doesUserHasNft,
                ticketBase64,
                isEventPending,
                userDeclined,
                wid,
                googleTaskIdChecker,
                isGoogleResponse,
                connectedGoogleAccount,
                setConnectedGoogleAccount,
                dappAuth,
                setDappAuth,
                dappLogin,
                isPending,
                setIsPending,
                errMsg,
                setIsGoogleResponse,
                dappEvents,
                updateTicketRoots,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
