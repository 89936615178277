import { useContext } from 'react'
import { useConnect } from 'wagmi'
import LoadingIcons from 'react-loading-icons'
import AppContext from '../../context/AppContext'

export default function WalletConnectionModal({ onClose }) {
    const { initApp } = useContext(AppContext)
    const { connect, connectors, isLoading } = useConnect({
        chainId: 80001,
        onSuccess(data) {
            onClose()
            initApp()
        },
    })

    return (
        <div className="modalBackground">
            <div className="walletConnectionModalContainer">
                {connectors.map((connector) => (
                    <button
                        disabled={!connector.ready}
                        key={connector.id}
                        onClick={() => {
                            !isLoading && connect({ connector })
                        }}
                    >
                        {isLoading ? <LoadingIcons.Puff /> : connector.name}
                    </button>
                ))}
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    )
}
