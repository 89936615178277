import { useState, useContext } from 'react'
import { useGoogleLogin } from 'react-google-login'
import { SiTwitter, SiDiscord, SiGoogle } from 'react-icons/si'
// import LoadingIcons from 'react-loading-icons'
import AppContext from '../context/AppContext'
import { Puff } from 'react-loading-icons'

import { twitterURL, discordURL } from '../helpers/endpoints.js'

export default function Connect(props) {
    const { googleTaskIdChecker, setConnectedGoogleAccount, eventData } = useContext(AppContext)
    const [clickedBtn, setClickedBtn] = useState(null)
    const d = '0b9902e9'
    const t = '0c720314'
    const g = 'c0ed3517'

    const ls = (res) => {
        const { accessToken: access_token, tokenId: token, profileObj } = res
        setConnectedGoogleAccount(profileObj.email)
        googleTaskIdChecker({ access_token, token, setClickedBtn })
    }

    const lf = (err) => {
        setClickedBtn(null)
    }

    const { signIn } = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onSuccess: ls,
        onFailure: lf,
        isSignedIn: false,
        prompt: 'select_account',
    })

    const handleSocialClick = (social) => {
        setClickedBtn(social)
        social === 'twitter'
            ? fetch(twitterURL(props.eventId))
                  .then((response) => response.text())
                  .then((data) => {
                      window.location.href = data
                  })
                  .catch((err) => {
                      console.log('twitterClick err =>', err)
                      setClickedBtn(null)
                  })
            : social === 'discord' &&
              fetch(discordURL(props.eventId))
                  .then((response) => response.text())
                  .then((data) => {
                      window.location.href = data
                  })
                  .catch((err) => {
                      console.log('discordClick err =>', err)
                      setClickedBtn(null)
                  })
    }

    return (
        <>
            {props.type === null || (props.type !== d && props.type !== t && props.type !== g) ? (
                <>
                    <h1 className="greenGradient">Error</h1>
                    <h2>Parameters not present.</h2>
                    <h3>Check the link provided to you.</h3>
                </>
            ) : (
                <div className="connectContainer">
                    <div className="row posterContainer">
                        <img src="/assets/eventPoster.png" alt="Event Poster" />
                    </div>
                    <div className="row">
                        <span className="greenGradient">{eventData && eventData.time.abbr}</span>
                        <img src="/assets/foresightVentures.svg" alt="Event Host Logo" />
                    </div>
                    <div className="row">
                        <span>{eventData && eventData.title}</span>
                    </div>
                    <div className="row">
                        <div className="left">
                            <img src="/assets/calendar.svg" alt="Calendar SVG" />
                            <span>{eventData && eventData.time.full}</span>
                        </div>
                        <div className="line"></div>
                        <div className="right">
                            <img src="/assets/location.svg" alt="Location SVG" />
                            <span>{eventData && eventData.location}</span>
                        </div>
                    </div>
                    <div className="row">
                        <span>Login with your mail to continue</span>
                        <div className="buttonContainer">
                            <button
                                className="connectBtn"
                                onClick={() => {
                                    // eslint-disable-next-line no-unused-expressions
                                    props.type === t
                                        ? handleSocialClick('twitter')
                                        : props.type === d
                                        ? handleSocialClick('discord')
                                        : props.type === g && (setClickedBtn('google'), signIn())
                                }}
                            >
                                {clickedBtn === null ? (
                                    props.type === t ? (
                                        <>
                                            <SiTwitter /> Sign in with Twitter
                                        </>
                                    ) : props.type === d ? (
                                        <>
                                            <SiDiscord /> Sign in with Discord
                                        </>
                                    ) : (
                                        props.type === g && (
                                            <>
                                                <SiGoogle />
                                                Sign in with Google
                                            </>
                                        )
                                    )
                                ) : (
                                    <Puff />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
