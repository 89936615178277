import { useState } from 'react'
import WalletConnectionModal from '../Modals/WalletConnectionModal'
import { useAccount, useDisconnect, useNetwork } from 'wagmi'
import allowedChainId from '../../helpers/allowedChainId.json'

export default function Header() {
    const [showModal, setShowModal] = useState(false)
    const { disconnect } = useDisconnect()
    const { address: connectedWalletAddress } = useAccount()
    const { chain } = useNetwork()

    return (
        <div className="headerContainer">
            <div className="headerContent">
                <div className="brandingContainer">
                    <img src="/assets/logo.png" alt="FirstBatch logo" />
                    <span>FirstBatch</span>
                </div>
                <div className="stateContainer">
                    {chain === undefined ? (
                        <button onClick={() => setShowModal(true)}>Connect</button>
                    ) : chain.id === allowedChainId ? (
                        <>
                            <div className="connectionDetails">
                                <span className="walletId">
                                    {connectedWalletAddress.slice(0, 6) + '...' + connectedWalletAddress.slice(-4)}
                                </span>
                            </div>
                            <button onClick={disconnect}>Disconnect</button>
                        </>
                    ) : (
                        <button onClick={() => disconnect()}>Disconnect</button>
                    )}
                </div>
            </div>
            {showModal && <WalletConnectionModal onClose={() => setShowModal(false)} />}
        </div>
    )
}
