import { useContext, useEffect } from 'react'
import { useNetwork } from 'wagmi'
import AppContext from '../../context/AppContext'
import UserWarning from './UserWarning'
import Header from './Header'
import { gapi } from 'gapi-script'

const allowedChainId = 80001

export default function Layout({ children }) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    const { isInitializing, didAppInitialized, isEventPending, isTaskIdPending, doesUserHasNft } = useContext(AppContext)
    const { chain } = useNetwork()

    useEffect(() => {
        const start = () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: '',
            })
        }

        gapi.load('client:auth2', start)
    }, [])

    return (
        <>
            <div className="layoutContainer">
                {isInitializing ? (
                    <UserWarning type="initializing" />
                ) : chain === undefined ? (
                    <UserWarning type="unauthorized" />
                ) : isEventPending || isTaskIdPending ? (
                    <UserWarning type="loadingEvent" />
                ) : isMobile && !doesUserHasNft ? (
                    <div className="mobileWarning">
                        <div className="bgGradient">
                            <img src="/assets/bgGradient.png" alt="bg gradient" />
                        </div>
                        <h1>You can mint your ticket only in Desktop</h1>
                    </div>
                ) : chain.id === allowedChainId || doesUserHasNft ? (
                    didAppInitialized === true ? (
                        <>
                            <Header />
                            <div className="bgGradientContainer">
                                <img src="/assets/bgGradient.png" alt="bg gradiente" />
                            </div>
                            <main>
                                <section>{children}</section>
                            </main>
                        </>
                    ) : (
                        didAppInitialized === false && <UserWarning type="retryInit" />
                    )
                ) : (
                    <UserWarning type="incorrectChain" />
                )}
            </div>
        </>
    )
}
