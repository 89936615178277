import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import AppContext from '../../context/AppContext'
import { QRCodeSVG } from 'qrcode.react'
import { checkUserTicketURL } from '../../helpers/endpoints'

export default function QrModal({ onClose }) {
    const { wid } = useContext(AppContext)
    const { eventId } = useParams()

    return (
        <div className="modalBackground">
            <div className="qrModalContainer">
                <div className="qrCode">
                    <QRCodeSVG
                        value={`${checkUserTicketURL(eventId, wid)}`}
                        size={'100%'}
                        bgColor="#fff"
                        fgColor="#000"
                        includeMargin="true"
                    />
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    )
}
