import React from 'react'
import ReactDOM from 'react-dom/client'
import { RainbowKitProvider, darkTheme, connectorsForWallets, wallet } from '@rainbow-me/rainbowkit'
import { configureChains, createClient, chain, WagmiConfig } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { AppProvider } from './context/AppContext'
import App from './App'
import '@rainbow-me/rainbowkit/dist/index.css'
import './scss/main.scss'

const { chains, provider } = configureChains(
    [chain.polygonMumbai],
    [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_API_KEY, priority: 0, weight: 1 }), publicProvider()]
)

const WagmiClient = createClient({
    autoConnect: true,
    connectors: connectorsForWallets([
        {
            groupName: 'Recommended',
            wallets: [wallet.metaMask({ chains })],
        },
    ]),
    provider,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <WagmiConfig client={WagmiClient}>
            <RainbowKitProvider modalSize="compact" theme={darkTheme()} chains={chains}>
                <AppProvider>
                    <App />
                </AppProvider>
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>
)
