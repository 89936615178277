import { useContext } from 'react'
import { RiCloseLine, RiCheckFill } from 'react-icons/ri'
import LoadingIcons from 'react-loading-icons'
import AppContext from '../context/AppContext.js'
import mintingSteps from '../helpers/mintingSteps.json'

export default function ProcessSteps({ type }) {
    const { mintingStep, mintingErr, isEventPending, isTaskIdPending } = useContext(AppContext)

    return (
        <>
            {type === 'init' ? (
                <div className="processSteps">
                    <div className="step">
                        <div className="statusContainer">
                            <LoadingIcons.Puff />
                        </div>
                        <span>Initializing</span>
                    </div>
                </div>
            ) : type === 'mint' ? (
                <div className="processSteps">
                    {mintingSteps.map((step, stepIndex) => {
                        return (
                            <div className="step" key={`mintingStep${stepIndex}`}>
                                <div className="statusContainer">
                                    {mintingStep === stepIndex && mintingErr === false && <LoadingIcons.Puff />}
                                    {mintingStep === stepIndex && mintingErr === true && <RiCloseLine />}
                                    {mintingStep > stepIndex && <RiCheckFill />}
                                </div>
                                <span>{step}</span>
                            </div>
                        )
                    })}
                </div>
            ) : (
                type === 'loadingEvent' && (
                    <div className="processSteps">
                        {isEventPending !== null && (
                            <div className="step">
                                <div className="statusContainer">
                                    {isEventPending === true ? (
                                        <LoadingIcons.Puff />
                                    ) : (
                                        isEventPending === false && <RiCheckFill />
                                    )}
                                </div>
                                <span>Loading Event</span>
                            </div>
                        )}
                        {isTaskIdPending !== null && (
                            <div className="step">
                                <div className="statusContainer">
                                    {isTaskIdPending === true ? (
                                        <LoadingIcons.Puff />
                                    ) : (
                                        isTaskIdPending === false && <RiCheckFill />
                                    )}
                                </div>
                                <span>Analyzing Account</span>
                            </div>
                        )}
                    </div>
                )
            )}
        </>
    )
}
