import { useState, useEffect, useContext } from 'react'
import { Puff } from 'react-loading-icons'
import AppContext from '../context/AppContext'
import Layout from '../components/Layout'
import Event from '../components/Event'

export default function DappUI() {
    const { dappAuth, dappLogin, isPending, setIsPending, errMsg, dappEvents } = useContext(AppContext)
    const [focusedEventId, setFocusedEventId] = useState('')
    const [focusedRow, setFocusedRow] = useState('eligibleUsers')
    const [userInput, setUserInput] = useState('')
    const [stagedUsers, setStagedUsers] = useState([])
    const [dappName, setDappName] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        setUserInput('')
        setStagedUsers([])
    }, [focusedEventId])

    return (
        <Layout>
            <div className="dappUIPageWrapper">
                {dappAuth ? (
                    dappEvents.length !== 0 &&
                    dappEvents.map((event, index) => {
                        return (
                            <Event
                                key={`event${index}`}
                                event={event}
                                index={index}
                                focusedEventId={focusedEventId}
                                setFocusedEventId={setFocusedEventId}
                                focusedRow={focusedRow}
                                setFocusedRow={setFocusedRow}
                                userInput={userInput}
                                setUserInput={setUserInput}
                                stagedUsers={stagedUsers}
                                setStagedUsers={setStagedUsers}
                            />
                        )
                    })
                ) : (
                    <form
                        className="loginForm"
                        onSubmit={(e) => {
                            e.preventDefault()
                            setIsPending(true)
                            dappLogin(dappName, password)
                        }}
                    >
                        <input
                            placeholder="Dapp Name"
                            value={dappName}
                            onChange={(e) => setDappName(e.target.value)}
                            type="text"
                        />
                        <input
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                        />
                        {errMsg !== '' && <span>{errMsg}</span>}
                        <button>{isPending ? <Puff /> : 'Login'}</button>
                    </form>
                )}
            </div>
        </Layout>
    )
}
