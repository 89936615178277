// https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/ -> https://api.firstbatch.xyz/
// https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/ -> https://api2.firstbatch.xyz/
// https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/ —> https://api3.firstbatch.xyz/

export function imageUploadURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? 'https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/1/ipfs/image-upload'
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? 'https://api3.firstbatch.xyz/1/ipfs/image-upload'
        : null
}

export function jsonUploadURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? 'https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/1/ipfs/json-upload'
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? 'https://api3.firstbatch.xyz/1/ipfs/json-upload'
        : null
}

export function discordURL(eventId) {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/login/discord?callback_url=ticketing/discord&event_id=${eventId}`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? // `https://api2.firstbatch.xyz/login/discord?callback_url=ticketing/discord&event_id=${eventId}`
          `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/login/discord?callback_url=ticketing/discord&event_id=${eventId}`
        : null
}

export function twitterURL(eventId) {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/twitter_auth?callback_url=ticketing/twitter&event_id=${eventId}`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? // ? `https://api2.firstbatch.xyz/twitter_auth?callback_url=ticketing/twitter&event_id=${eventId}`
          `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/twitter_auth?callback_url=ticketing/twitter&event_id=${eventId}`
        : null
}

export function getTicketingEventURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? 'https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/get_ticketing_event'
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? 'https://api.firstbatch.xyz/get_ticketing_event'
        : null
}

export function checkUserTicketURL(eventId, wid) {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/check_user_ticket?event_id=${eventId}&wid=${wid}`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://api.firstbatch.xyz/check_user_ticket?event_id=${eventId}&wid=${wid}`
        : null
}

export function taskIdCheckerURL(taskId) {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/tasks/${taskId}`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/tasks/${taskId}`
        : // `https://api2.firstbatch.xyz/2/tasks/${taskId}`
          null
}

export function googleURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/auth_google`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://api2.firstbatch.xyz/2/auth_google`
        : null
}

export function getTicketingEventsURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/get_ticketing_events`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://api.firstbatch.xyz/get_ticketing_events`
        : null
}

export function ticketingLoginURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/ticketing_login`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://api.firstbatch.xyz/ticketing_login`
        : null
}

export function updateTicketRootsURL() {
    return process.env.REACT_APP_ENDPOINT_TARGET === 'test'
        ? `https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/update-ticket-roots`
        : process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
        ? `https://api3.firstbatch.xyz/1/contract/update-ticket-roots`
        : null
}

export function polygonScanURL(txHash) {
    return `https://api.polygonscan.com/api?module=transaction&action=gettxreceiptstatus&txhash=${txHash}&apikey=${process.env.REACT_APP_POLYGONSCAN_API_KEY}`
}
