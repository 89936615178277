import { useState, useContext } from 'react'
import AppContext from '../context/AppContext'
import QrModal from './Modals/QrModal'

export default function Ticket() {
    const { ticketBase64, eventData } = useContext(AppContext)
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="ticketContainer">
            <h1>{eventData && eventData.title}</h1>
            <h2>
                by <img src="/assets/foresightVentures.svg" alt="Host Logo" />
            </h2>
            <div className="nftContainer">
                <img src={ticketBase64} alt="Ticket PNG" />
            </div>
            <button onClick={() => setShowModal(true)}>Show QR Code</button>
            {showModal && <QrModal onClose={() => setShowModal(false)} />}
        </div>
    )
}
