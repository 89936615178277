import UserWarning from '../components/Layout/UserWarning.js'
import Layout from '../components/Layout'

export default function NotFound() {
    return (
        <Layout>
            <UserWarning type="notFound" />
        </Layout>
    )
}
