import { useSearchParams } from 'react-router-dom'

export default function Verification() {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const wid = searchParams.get('wid')
    const success = searchParams.get('success')

    return (
        <div className="layoutContainer verificationContainer">
            <div className="headerContainer">
                <div className="headerContent">
                    <div className="brandingContainer">
                        <img src="/assets/logo.png" alt="FirstBatch logo" />
                        <span>FirstBatch</span>
                    </div>
                </div>
            </div>
            <div className="bgGradientContainer">
                <img src="/assets/bgGradientGreen.png" alt="bg gradiente" />
            </div>
            <div className="pageContent">
                {wid === null || success === null ? (
                    <>
                        <h1 className="blueGradient">Error</h1>
                        <h2>Your authorization expired</h2>
                        <h3>Please re-authorize from the link that provided to you.</h3>
                    </>
                ) : success === 'true' ? (
                    <>
                        <h1 className="greenGradient">Success</h1>
                        <h2>User is eligible</h2>
                    </>
                ) : (
                    success === 'false' && (
                        <>
                            <h1 className="blueGradient">Fail</h1>
                            <h2>User is not eligible</h2>
                        </>
                    )
                )}
            </div>
        </div>
    )
}
